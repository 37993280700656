import { storeToRefs } from "pinia";
import { useAuthUserStore } from "~/store/users";
import apiResponse from "@/interfaces/apiResponse";

export default defineNuxtRouteMiddleware((to) => {
  const useAuthStore = useAuthUserStore();
  const { setAuthenticatedUser, isValidToken } = useAuthStore;
  const { authenticatedUser } = storeToRefs(useAuthStore);

  const userToken = useCookie("token");

  if (to.matched.length === 0) {
    return;
  }

  if (
    !userToken.value &&
    to.path !== "/login" &&
    to.path !== "/recuperar-senha"
  ) {
    return navigateTo("/login");
  }
  const validateToken = async () => {
    const { data, error } = await isValidToken();
    if (error.value) {
      setAuthenticatedUser({});
      userToken.value = null;
      return navigateTo("/login");
    }
    const isValid = data.value;
    return isValid;
  };
  if (!authenticatedUser.value.auth && to.path !== "/login" && userToken) {
    validateToken()
      .then((isValid: apiResponse) => {
        if (isValid.statusCode === 200) {
          setAuthenticatedUser(isValid.data);
        }
        const userType = authenticatedUser.value?.type;
        const commonPaths = ["/login", "/dashboard", "/relatorio", "/status"];

        const redirectInfo = [
          { userType: "technician", path: "/status" },
          {
            userType: "franchisee",
            path: "/dashboard",
            exclude: [
              ...commonPaths,
              "/assinantes",
              "/assinantes/criar",
              "/contratos.view",
              "/anunciantes",
              "/anunciantes/criar",
              "/financeiro",
              "/financeiro/criar",
              "/terminais/criar",
            ],
          },
          {
            userType: "admin",
            path: "/dashboard",
            exclude: [
              ...commonPaths,
              "/franqueados",
              "/franqueados/criar",
              "/tecnicos",
              "/tecnicos/criar",
              "/anunciantes",
              "/anunciantes/criar",
            ],
            allowEdit: /^anunciantes\/[a-f0-9]{24}\/editar$/.test(to.path),
          },
        ].find(
          (condition) =>
            condition.userType === userType &&
            condition.path !== to.path &&
            (!condition.exclude || !condition.exclude.includes(to.path)) &&
            // Adicione esta nova condição
            (!condition.allowEdit || condition.allowEdit === true)
        );

        return redirectInfo ? navigateTo(redirectInfo.path) : undefined;
      })
      .catch((error) => {
        console.log(error);
      });
  } else if (
    authenticatedUser.value?.type === "technician" &&
    to.path !== "/status" &&
    to.path !== "/login"
  ) {
    return navigateTo("/status");
  }
});
