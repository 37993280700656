import { defineStore } from "pinia";

export const useAlertStore = defineStore({
  id: "alert-store",
  state: () => {
    return {
      alertInfo: {},
      showAlertInfo: false,
    };
  },
  actions: {
    setAlert(value: object) {
      this.alertInfo = value;
      this.showAlertInfo = true;
    },
    setShowAlert(value: boolean) {
      this.showAlertInfo = value;
    },
  },
  getters: {
    alert: (state) => state.alertInfo,
    showAlert: (state) => state.showAlertInfo,
  },
});
