<template>
  <div class="text-center ma-2">
    <v-snackbar v-model="showAlert" color="#FAFFF5">
      <div class="d-flex align-center">
        <div>
          <v-icon
            size="x-large"
            :class="[
              'text-color-' + alert.color,
              'background-color-' + alert.color,
              'icon-container',
            ]"
            class="mr-2 icon-container"
          >
            {{ alert.icon }}</v-icon
          >
        </div>

        <div class="pl-6">
          <div class="text-subtitle-1 pb-2" style="color: #54595f">
            {{ alert.message.title }}
          </div>
          <p :class="'text-color-' + alert.color">{{ alert.message.text }}</p>
        </div>
      </div>

      <template #actions>
        <v-btn
          style="color: #8d8d8d"
          variant="text"
          @click="internalModelValue = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useAlertStore } from "@/store/alert";

const alertStore = useAlertStore();
const { alert, showAlert } = storeToRefs(alertStore);
const { setShowAlert } = alertStore;

const internalModelValue = ref(showAlert);

watch(showAlert, (newVal) => {
  if (newVal) {
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  }
});
</script>

<style scoped>
.text-color-red {
  color: #ff0000;
}

.text-color-blue {
  color: #0d3de7;
}

.text-color-green {
  color: #afc779;
}

.icon-container {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.background-color-red {
  background-color: #f8efef;
}

.background-color-green {
  background-color: #eef4e1;
}

.background-color-blue {
  background-color: #e4e7f2;
}
</style>
