<template>
  <div>
    <NuxtLayout v-if="authenticatedUser?._id || route.path === '/login'">
    </NuxtLayout>
    <SnackBar />
  </div>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { useAuthUserStore } from "~/store/users";
const { authenticatedUser } = storeToRefs(useAuthUserStore());

const route = useRoute();
</script>
<style lang="scss">
body {
  font-family: "Poppins", sans-serif;
  background: linear-gradient(
      360deg,
      #f2f9e280 0%,
      rgba(240, 249, 222, 0.5) 100%
    ),
    #fff;
}
</style>
