import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

export default defineNuxtPlugin((nuxtApp: any) => {
  const vuetify = createVuetify({
    ssr: true,
    components,
    directives,
    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            "stv-green-darken": "#5C6D32",
            "stv-green-lighten": "#FAFFF5",
          },
        },
      },
    },
  });
  nuxtApp.vueApp.use(vuetify);
});
