import { defineStore } from "pinia";

export const useAuthUserStore = defineStore({
  id: "auth-user-store",
  state: () => {
    return {
      authUserInfo: {},
    };
  },
  actions: {
    setAuthenticatedUser(value: object) {
      this.authUserInfo = value;
    },

    async isValidToken() {
      const response = await useFetch(`/api/auth/validate`);
      return response;
    },
  },
  getters: {
    authenticatedUser: (state) => state.authUserInfo,
  },
});
