import { default as editar6DIkbrBfM1Meta } from "/vercel/path0/pages/anunciantes/[id]/editar.vue?macro=true";
import { default as criarOYBZ3OFau9Meta } from "/vercel/path0/pages/anunciantes/criar.vue?macro=true";
import { default as indexbvchPQmGo5Meta } from "/vercel/path0/pages/anunciantes/index.vue?macro=true";
import { default as criarGaURmVHdDnMeta } from "/vercel/path0/pages/assinantes/[id]/criar.vue?macro=true";
import { default as criarAkp3u1OgtuMeta } from "/vercel/path0/pages/assinantes/criar.vue?macro=true";
import { default as index6eXhhCGYoVMeta } from "/vercel/path0/pages/assinantes/index.vue?macro=true";
import { default as _91id_93RAIHrNL5mNMeta } from "/vercel/path0/pages/assinantes/terminais/[id].vue?macro=true";
import { default as indexeyu5STnL58Meta } from "/vercel/path0/pages/cadastro-de-franqueado/index.vue?macro=true";
import { default as index1E96u3aR9mMeta } from "/vercel/path0/pages/conteudos/index.vue?macro=true";
import { default as indexRIIpWdUktDMeta } from "/vercel/path0/pages/dashboard/index.vue?macro=true";
import { default as indexQhb55IDraWMeta } from "/vercel/path0/pages/dashboard2/containerInfo/index.vue?macro=true";
import { default as index9O9LocAxuXMeta } from "/vercel/path0/pages/dashboard2/containerTable/index.vue?macro=true";
import { default as index3lkNjOftxSMeta } from "/vercel/path0/pages/dashboard2/index.vue?macro=true";
import { default as criarpd81Q9S5aIMeta } from "/vercel/path0/pages/financeiro/criar.vue?macro=true";
import { default as indexoECrqN3dFJMeta } from "/vercel/path0/pages/financeiro/index.vue?macro=true";
import { default as editarDY5fnv6Mh4Meta } from "/vercel/path0/pages/franqueados/[id]/editar.vue?macro=true";
import { default as criar31W7ZWxRZLMeta } from "/vercel/path0/pages/franqueados/criar.vue?macro=true";
import { default as indexxK64JBBm1eMeta } from "/vercel/path0/pages/franqueados/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexoOBDY93e9EMeta } from "/vercel/path0/pages/login/index.vue?macro=true";
import { default as indexdksLA0C5KzMeta } from "/vercel/path0/pages/meu-perfil/[id]/index.vue?macro=true";
import { default as indexP32mHPXWoHMeta } from "/vercel/path0/pages/recuperar-senha/index.vue?macro=true";
import { default as indexwgJreBcbYTMeta } from "/vercel/path0/pages/relatorio/containerCharts/index.vue?macro=true";
import { default as indexYqz1d1tjNQMeta } from "/vercel/path0/pages/relatorio/containerFilters/index.vue?macro=true";
import { default as indexf3tS6GOfR0Meta } from "/vercel/path0/pages/relatorio/index.vue?macro=true";
import { default as indexdGHhxkg8e2Meta } from "/vercel/path0/pages/status/index.vue?macro=true";
import { default as editarKRRHz5FBzGMeta } from "/vercel/path0/pages/tecnicos/[id]/editar.vue?macro=true";
import { default as criarKfEQ9wqIZ1Meta } from "/vercel/path0/pages/tecnicos/criar.vue?macro=true";
import { default as indexOlLLLnehdgMeta } from "/vercel/path0/pages/tecnicos/index.vue?macro=true";
export default [
  {
    name: editar6DIkbrBfM1Meta?.name ?? "anunciantes-id-editar",
    path: editar6DIkbrBfM1Meta?.path ?? "/anunciantes/:id()/editar",
    meta: editar6DIkbrBfM1Meta || {},
    alias: editar6DIkbrBfM1Meta?.alias || [],
    redirect: editar6DIkbrBfM1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/anunciantes/[id]/editar.vue").then(m => m.default || m)
  },
  {
    name: criarOYBZ3OFau9Meta?.name ?? "anunciantes-criar",
    path: criarOYBZ3OFau9Meta?.path ?? "/anunciantes/criar",
    meta: criarOYBZ3OFau9Meta || {},
    alias: criarOYBZ3OFau9Meta?.alias || [],
    redirect: criarOYBZ3OFau9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/anunciantes/criar.vue").then(m => m.default || m)
  },
  {
    name: indexbvchPQmGo5Meta?.name ?? "anunciantes",
    path: indexbvchPQmGo5Meta?.path ?? "/anunciantes",
    meta: indexbvchPQmGo5Meta || {},
    alias: indexbvchPQmGo5Meta?.alias || [],
    redirect: indexbvchPQmGo5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/anunciantes/index.vue").then(m => m.default || m)
  },
  {
    name: criarGaURmVHdDnMeta?.name ?? "assinantes-id-criar",
    path: criarGaURmVHdDnMeta?.path ?? "/assinantes/:id()/criar",
    meta: criarGaURmVHdDnMeta || {},
    alias: criarGaURmVHdDnMeta?.alias || [],
    redirect: criarGaURmVHdDnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/assinantes/[id]/criar.vue").then(m => m.default || m)
  },
  {
    name: criarAkp3u1OgtuMeta?.name ?? "assinantes-criar",
    path: criarAkp3u1OgtuMeta?.path ?? "/assinantes/criar",
    meta: criarAkp3u1OgtuMeta || {},
    alias: criarAkp3u1OgtuMeta?.alias || [],
    redirect: criarAkp3u1OgtuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/assinantes/criar.vue").then(m => m.default || m)
  },
  {
    name: index6eXhhCGYoVMeta?.name ?? "assinantes",
    path: index6eXhhCGYoVMeta?.path ?? "/assinantes",
    meta: index6eXhhCGYoVMeta || {},
    alias: index6eXhhCGYoVMeta?.alias || [],
    redirect: index6eXhhCGYoVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/assinantes/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93RAIHrNL5mNMeta?.name ?? "assinantes-terminais-id",
    path: _91id_93RAIHrNL5mNMeta?.path ?? "/assinantes/terminais/:id()",
    meta: _91id_93RAIHrNL5mNMeta || {},
    alias: _91id_93RAIHrNL5mNMeta?.alias || [],
    redirect: _91id_93RAIHrNL5mNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/assinantes/terminais/[id].vue").then(m => m.default || m)
  },
  {
    name: indexeyu5STnL58Meta?.name ?? "cadastro-de-franqueado",
    path: indexeyu5STnL58Meta?.path ?? "/cadastro-de-franqueado",
    meta: indexeyu5STnL58Meta || {},
    alias: indexeyu5STnL58Meta?.alias || [],
    redirect: indexeyu5STnL58Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/cadastro-de-franqueado/index.vue").then(m => m.default || m)
  },
  {
    name: index1E96u3aR9mMeta?.name ?? "conteudos",
    path: index1E96u3aR9mMeta?.path ?? "/conteudos",
    meta: index1E96u3aR9mMeta || {},
    alias: index1E96u3aR9mMeta?.alias || [],
    redirect: index1E96u3aR9mMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/conteudos/index.vue").then(m => m.default || m)
  },
  {
    name: indexRIIpWdUktDMeta?.name ?? "dashboard",
    path: indexRIIpWdUktDMeta?.path ?? "/dashboard",
    meta: indexRIIpWdUktDMeta || {},
    alias: indexRIIpWdUktDMeta?.alias || [],
    redirect: indexRIIpWdUktDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexQhb55IDraWMeta?.name ?? "dashboard2-containerInfo",
    path: indexQhb55IDraWMeta?.path ?? "/dashboard2/containerInfo",
    meta: indexQhb55IDraWMeta || {},
    alias: indexQhb55IDraWMeta?.alias || [],
    redirect: indexQhb55IDraWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard2/containerInfo/index.vue").then(m => m.default || m)
  },
  {
    name: index9O9LocAxuXMeta?.name ?? "dashboard2-containerTable",
    path: index9O9LocAxuXMeta?.path ?? "/dashboard2/containerTable",
    meta: index9O9LocAxuXMeta || {},
    alias: index9O9LocAxuXMeta?.alias || [],
    redirect: index9O9LocAxuXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard2/containerTable/index.vue").then(m => m.default || m)
  },
  {
    name: index3lkNjOftxSMeta?.name ?? "dashboard2",
    path: index3lkNjOftxSMeta?.path ?? "/dashboard2",
    meta: index3lkNjOftxSMeta || {},
    alias: index3lkNjOftxSMeta?.alias || [],
    redirect: index3lkNjOftxSMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard2/index.vue").then(m => m.default || m)
  },
  {
    name: criarpd81Q9S5aIMeta?.name ?? "financeiro-criar",
    path: criarpd81Q9S5aIMeta?.path ?? "/financeiro/criar",
    meta: criarpd81Q9S5aIMeta || {},
    alias: criarpd81Q9S5aIMeta?.alias || [],
    redirect: criarpd81Q9S5aIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/financeiro/criar.vue").then(m => m.default || m)
  },
  {
    name: indexoECrqN3dFJMeta?.name ?? "financeiro",
    path: indexoECrqN3dFJMeta?.path ?? "/financeiro",
    meta: indexoECrqN3dFJMeta || {},
    alias: indexoECrqN3dFJMeta?.alias || [],
    redirect: indexoECrqN3dFJMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/financeiro/index.vue").then(m => m.default || m)
  },
  {
    name: editarDY5fnv6Mh4Meta?.name ?? "franqueados-id-editar",
    path: editarDY5fnv6Mh4Meta?.path ?? "/franqueados/:id()/editar",
    meta: editarDY5fnv6Mh4Meta || {},
    alias: editarDY5fnv6Mh4Meta?.alias || [],
    redirect: editarDY5fnv6Mh4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/franqueados/[id]/editar.vue").then(m => m.default || m)
  },
  {
    name: criar31W7ZWxRZLMeta?.name ?? "franqueados-criar",
    path: criar31W7ZWxRZLMeta?.path ?? "/franqueados/criar",
    meta: criar31W7ZWxRZLMeta || {},
    alias: criar31W7ZWxRZLMeta?.alias || [],
    redirect: criar31W7ZWxRZLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/franqueados/criar.vue").then(m => m.default || m)
  },
  {
    name: indexxK64JBBm1eMeta?.name ?? "franqueados",
    path: indexxK64JBBm1eMeta?.path ?? "/franqueados",
    meta: indexxK64JBBm1eMeta || {},
    alias: indexxK64JBBm1eMeta?.alias || [],
    redirect: indexxK64JBBm1eMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/franqueados/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexoOBDY93e9EMeta?.name ?? "login",
    path: indexoOBDY93e9EMeta?.path ?? "/login",
    meta: indexoOBDY93e9EMeta || {},
    alias: indexoOBDY93e9EMeta?.alias || [],
    redirect: indexoOBDY93e9EMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexdksLA0C5KzMeta?.name ?? "meu-perfil-id",
    path: indexdksLA0C5KzMeta?.path ?? "/meu-perfil/:id()",
    meta: indexdksLA0C5KzMeta || {},
    alias: indexdksLA0C5KzMeta?.alias || [],
    redirect: indexdksLA0C5KzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meu-perfil/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexP32mHPXWoHMeta?.name ?? "recuperar-senha",
    path: indexP32mHPXWoHMeta?.path ?? "/recuperar-senha",
    meta: indexP32mHPXWoHMeta || {},
    alias: indexP32mHPXWoHMeta?.alias || [],
    redirect: indexP32mHPXWoHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/recuperar-senha/index.vue").then(m => m.default || m)
  },
  {
    name: indexwgJreBcbYTMeta?.name ?? "relatorio-containerCharts",
    path: indexwgJreBcbYTMeta?.path ?? "/relatorio/containerCharts",
    meta: indexwgJreBcbYTMeta || {},
    alias: indexwgJreBcbYTMeta?.alias || [],
    redirect: indexwgJreBcbYTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/relatorio/containerCharts/index.vue").then(m => m.default || m)
  },
  {
    name: indexYqz1d1tjNQMeta?.name ?? "relatorio-containerFilters",
    path: indexYqz1d1tjNQMeta?.path ?? "/relatorio/containerFilters",
    meta: indexYqz1d1tjNQMeta || {},
    alias: indexYqz1d1tjNQMeta?.alias || [],
    redirect: indexYqz1d1tjNQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/relatorio/containerFilters/index.vue").then(m => m.default || m)
  },
  {
    name: indexf3tS6GOfR0Meta?.name ?? "relatorio",
    path: indexf3tS6GOfR0Meta?.path ?? "/relatorio",
    meta: indexf3tS6GOfR0Meta || {},
    alias: indexf3tS6GOfR0Meta?.alias || [],
    redirect: indexf3tS6GOfR0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/relatorio/index.vue").then(m => m.default || m)
  },
  {
    name: indexdGHhxkg8e2Meta?.name ?? "status",
    path: indexdGHhxkg8e2Meta?.path ?? "/status",
    meta: indexdGHhxkg8e2Meta || {},
    alias: indexdGHhxkg8e2Meta?.alias || [],
    redirect: indexdGHhxkg8e2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/status/index.vue").then(m => m.default || m)
  },
  {
    name: editarKRRHz5FBzGMeta?.name ?? "tecnicos-id-editar",
    path: editarKRRHz5FBzGMeta?.path ?? "/tecnicos/:id()/editar",
    meta: editarKRRHz5FBzGMeta || {},
    alias: editarKRRHz5FBzGMeta?.alias || [],
    redirect: editarKRRHz5FBzGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/tecnicos/[id]/editar.vue").then(m => m.default || m)
  },
  {
    name: criarKfEQ9wqIZ1Meta?.name ?? "tecnicos-criar",
    path: criarKfEQ9wqIZ1Meta?.path ?? "/tecnicos/criar",
    meta: criarKfEQ9wqIZ1Meta || {},
    alias: criarKfEQ9wqIZ1Meta?.alias || [],
    redirect: criarKfEQ9wqIZ1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/tecnicos/criar.vue").then(m => m.default || m)
  },
  {
    name: indexOlLLLnehdgMeta?.name ?? "tecnicos",
    path: indexOlLLLnehdgMeta?.path ?? "/tecnicos",
    meta: indexOlLLLnehdgMeta || {},
    alias: indexOlLLLnehdgMeta?.alias || [],
    redirect: indexOlLLLnehdgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/tecnicos/index.vue").then(m => m.default || m)
  }
]